<template>
  <div
    class="form-group d-flex flex-column justify-content-center px-8 px-lg-10"
  >
    <div class="qrcode" v-if="isSell">
      <img v-if="getQrCode" :src="getQrCode" alt="Wallet QR" />
      <div class="d-flex mt-2 align-items-center">
        <span>{{ transactionSummary.wallet }}</span>
        <button class="btn icon-button" @click="copyText(transactionSummary.wallet)">
          <i class="fa fa-copy"></i>
        </button>
      </div>
    </div>
    <div class="row" style="row-gap: 5px;">
      <div class="col-12 col-md-6">
        <label>{{ $t("branch_dash.customer_transaction") }}</label>
        <p>
          {{
            $t(
              `branch_dash.wants_to_${transactionSummary.selectedCustomerTransaction.type}`,
              { code: transactionSummary.selectedCustomerTransaction.code }
            )
          }}
        </p>
      </div>
      <div class="col-12 col-md-6">
        <label>{{
          $t("commons.commission")
        }}</label>
        <p>{{ transactionSummary.fee_formatted }}</p>
      </div>
      <div class="col-12 col-md-6">
        <label>{{ $t("branch_dash.current_price") }}</label>
        <p>{{ transactionSummary.price_formatted }}</p>
      </div>
      <div class="col-12 col-md-6">
        <label>{{
          $t("branch_dash.amount")
        }}</label>
        <p>{{ transactionSummary.amount_str }}</p>
      </div>
      <div class="col-12 col-md-6">
        <label>{{ $t("branch_dash.sub_total") }}</label>
        <p>{{ transactionSummary.subTotal_formatted }}</p>
      </div>
      <div class="col-12 col-md-6">
        <label>{{
          $t("branch_dash.commission_amount")
        }}</label>
        <p>{{ transactionSummary.fee_formatted }}</p>
      </div>
      <div class="col-12 col-md-6">
        <label>{{ $t("branch_dash.total_volume") }}</label>
        <p>{{ transactionSummary.grandTotal_formatted }}</p>
      </div>
      <div class="col-12 col-md-6">
        <label
          v-if="transactionSummary.selectedTransactionType === 'sell'"
          class="text-danger"
          >{{ $t("branch_dash.payable_to_customer") }}</label
        >
        <label
          v-if="transactionSummary.selectedTransactionType === 'buy'"
          >{{ $t("branch_dash.customer_payment") }}</label
        >
        <p class="text-danger">{{ transactionSummary.grandTotal_formatted }}</p>
      </div>
  
      <div class="col-12">
        <label>{{ $t("branch_dash.inc_commission") }}: {{ $t(transactionSummary.includeFee) }}</label>
      </div>
    </div>

    <h3 class="my-5 font-weight-bold text-dark">
      {{ $t("branch_dash.customer_information_title") }}
    </h3>

    <div class="row mt-3">
      <div class="col-12 col-md-6">
        <label>{{ $t("branch_dash.sales") }}</label>
        <p>{{ transactionSummary.customer.order_id }}</p>
      </div>
      <div class="col-12 col-md-6">
        <label>{{ $t("commons.name_surname") }}</label>
        <p>{{ maskFullName(transactionSummary.customer.fullname) }}</p>
      </div>
      <div class="col-12 col-md-6">
        <label>{{ $t("commons.mobile_phone") }}</label>
        <p>{{ maskPhoneNumber(transactionSummary.customer.phone) }}</p>
      </div>
      <div class="col-12 col-md-6">
        <label>{{ $t("commons.e_mail") }}</label>
        <p>{{ maskEmail(transactionSummary.customer.email) }}</p>
      </div>
    </div>
    <div class="row justify-content-end mt-3">
      <div class="d-flex justify-content-end border-top mt-5 pt-10">
        <div>
          <form-submit-btn
            :btnClass="'btn btn-secondary font-weight-bolder text-uppercase px-9 py-4 mr-2'"
            :isSubmitting="isSubmitting"
            @click="$emit('hideSummary')"
            :text="$t('commons.cancel')"
          />
        </div>
        <div>
          <form-submit-btn
            :btnClass="'btn btn-success font-weight-bolder text-uppercase px-9 py-4'"
            :isSubmitting="isSubmitting"
            @click="onApproveTransaction"
            :text="$t('commons.approve')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { maskEmail, maskFullName, maskPhoneNumber } from "../../utils";
export default {
  name: "TransactionSummary",
  data: () => ({
    isSubmitting: false,
  }),
  computed: {
    ...mapState({
      transactionSummary: (state) => state.transactions.transactionSummary,
      isSell: (state) => state.transactions.transactionSummary?.selectedTransactionType === 'sell'
    }),
    getQrCode() {
      return `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${encodeURIComponent(this.transactionSummary.wallet)}`
    }
  },
  methods: {
    onApproveTransaction() {
      this.isSubmitting = true;
      this.$store.dispatch("transactions/CREATE_TRANSACTION")
        .then(() => {
          this.isSubmitting = false;
          this.$emit('hideSummary');
        })
        .catch(() => {
          this.isSubmitting = false;
        });
    },
    async copyText(text) {
      await navigator.clipboard.writeText(text);
      this.$toast.info(this.$t("copied_to_clipboard"));
    },
    maskEmail,
    maskFullName,
    maskPhoneNumber
  }
};
</script>

<style lang="scss" scoped>
.row>.col-12.col-md-6 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    font-weight: bolder;
    padding: 0 !important;
    margin: 0 !important;
  }
}
.qrcode {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
</style>